import vuePDF from "vue-pdf";

export default {
  name: "Neraca",
  data() {
    return {
      dataForm: {
        lajur: "2",
        groupBy: "SBB",
        fileType: "pdf",
        officeIdList: [],
        date: "",
      },
      pdf: {
        src: null,
        pdfToDownload: null,
        numPages: 0,
      },
      isLoading: false,
      disabled: [],
      property: {
        modal: {
          showModalPreviewPDFNeraca: false,
        },
      },
      options: {
        officeIdList: [],
        officeListDataSoure: [],
        lajur: [],
        eksport: [],
        groupBy: [
          { text: "SBB", value: "SBB" },
          { text: "BB", value: "BB" },
        ],
      },
    };
  },
  methods: {
    selectAllOfficeList() {
      this.dataForm.officeIdList = this.options.officeIdList;
      this.options.officeIdList = [];
    },

    addOfficeList(event) {
      var unselectedOffice = this.options.officeIdList.filter(
        (index) => index.value !== event.value
      );
      this.options.officeIdList = unselectedOffice;
      this.disabled = [];
      this.dataForm.officeIdList.map((index) => {
        this.disabled.push(index.text);
      });
    },
    deleteOfficeList(event) {
      console.log(event);
      this.options.officeIdList.push(event);
      this.disabled = [];
    },
    async helperGetOfficeDataForReference() {
      const getUserPayload = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      if (getUserPayload !== "99") {
        this.$store
          .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
            params: {
              officeName: "",
              officeCode: `${getUserPayload}`,
              page: 0,
            },
            url: "office",
          })
          .then((response) => {
            response.data.data.content.map((index) => {
              const value = index.officeId;
              const text = index.officeName;
              this.options.officeIdList.push({
                value,
                text,
              });
              this.options.officeListDataSoure.push({
                value,
                text,
              });
            });
          });
      } else {
        this.$store
          .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
            params: {
              officeName: "",
              officeCode: "",
              page: 0,
            },
            url: "office",
          })
          .then((response) => {
            this.options.officeIdList = [
              { text: "-- Semua Cabang --", value: "" },
              { text: "-- Consolidasi --", value: "" },
            ];
            this.options.officeListDataSoure = [
              { text: "-- Semua Cabang --", value: "" },
              { text: "-- Consolidasi --", value: "" },
            ];
            response.data.data.content.map((index) => {
              const value = index.officeId;
              const text = index.officeName;
              this.options.officeIdList.push({
                value,
                text,
              });
              this.options.officeListDataSoure.push({
                value,
                text,
              });
            });
          });
      }
    },
    filterOfficeIdList(text) {
      console.log("triggered");
      this.options.officeIdList = this.options.officeListDataSoure.filter(
        (index) => {
          return (
            index.text
              .toString()
              .toLowerCase()
              .indexOf(text.toLowerCase()) >= 0
          );
        }
      );
    },
    async handleProccessNeraca() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Proses Data ?",
          type: "is-success",
          onConfirm: async () => {
            var ungroupingOffice = [];
            this.dataForm.officeIdList.map((index) => {
              ungroupingOffice.push(index.value);
            });
            console.log(ungroupingOffice);
            this.pdf.src = null;
            this.pdf.numPages = 0;
            this.pdf.pdfToDownload = null;
            this.isLoading = true;
            try {
              const payload = {
                officeId: ungroupingOffice.toString(),
                lajur: this.dataForm.lajur,
                date: this.dataForm.date,
                fileType: "pdf",
                groupBy: this.dataForm.groupBy,
              };
              const response = await this.$store.dispatch({
                type: "GET_SAVE_RESPONSE_DATA",
                endPoint: "report",
                reqUrl: "report/download-neraca/v2",
                payload: payload,
              });
              console.log("Success", response);
              console.log(response.data);
              const blob = new Blob([response.data]);
              console.log(blob);
              const objectUrl = URL.createObjectURL(blob);
              var load = vuePDF.createLoadingTask(objectUrl);
              this.pdf.pdfToDownload = objectUrl;
              this.pdf.src = load;
              this.pdf.src.promise.then((pdf) => {
                this.pdf.numPages = pdf.numPages;
              });
              this.property.modal.showModalPreviewPDFNeraca = true;
            } catch (error) {
              this.pdf.src = null;
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? `${error.response.statusText}`
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async onDownload() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      var ungroupingOffice = [];
      this.dataForm.officeIdList.map((index) => {
        ungroupingOffice.push(index.value);
      });
      const payload = {
        officeId: ungroupingOffice.toString(),
        lajur: this.dataForm.lajur,
        date: this.dataForm.date,
        fileType: this.dataForm.fileType,
        groupBy: this.dataForm.groupBy,
      };
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/download-neraca/v2",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(response.data);
        a.href = url;
        a.download = `Report_Neraca_${this.dataForm.lajur}_Lajur.${this.dataForm.fileType}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    async onDownloadZip() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      var ungroupingOffice = [];
      this.dataForm.officeIdList.map((index) => {
        ungroupingOffice.push(index.value);
      });
      const payload = {
        officeId: ungroupingOffice.toString(),
        lajur: this.dataForm.lajur,
        date: this.dataForm.date,
        fileType: this.dataForm.fileType,
        groupBy: this.dataForm.groupBy,
      };
      this.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/download-neraca/zip",
          payload: payload,
        });
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Report_Neraca_${this.dataForm.lajur}_Lajur.${"zip"}`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    closeModalPreviewPDFNeraca() {
      this.pdf.src = null;
      this.pdf.pdfToDownload = null;
      this.pdf.numPages = 0;
      this.property.modal.showModalPreviewPDFNeraca = false;
    },
    appendDefaultOptionSelect() {
      // const stringConditionDefaultOption = { value: "", text: "-- Pilih --" };
      // this.options.cabang = [stringConditionDefaultOption];
      // this.options.lajur = [stringConditionDefaultOption];
      // this.options.export = [stringConditionDefaultOption];
      var today = new Date();
      var yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      var yyyy = yesterday.getFullYear();
      var mm = String(yesterday.getMonth() + 1).padStart(2, "0");
      var dd = String(yesterday.getDate()).padStart(2, "0");
      var tanggalKemarin = yyyy + "-" + mm + "-" + dd;
      
      this.dataForm.date = tanggalKemarin;

      this.options.lajur.push(
        {
          value: "1",
          text: "1 Lajur",
        },
        {
          value: "2",
          text: "2 Lajur",
        }
      );
      this.options.eksport.push(
        {
          value: "pdf",
          text: "Pdf",
        },
        {
          value: "xls",
          text: "Excel",
        }
      );
    },
  },
  mounted() {
    this.helperGetOfficeDataForReference();
    this.appendDefaultOptionSelect();
  },
};
